:root {
    --surface-a:#ffffff;
    --surface-b:#faf9f8;
    --surface-c:#f3f2f1;
    --surface-d:#edebe9;
    --surface-e:#ffffff;
    --surface-f:#ffffff;
    --text-color:#323130;
    --text-color-secondary:#605e5c;
    --primary-color:#9A294B;
    --primary-color-text:#ffffff;
    --font-family:-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    --surface-0: #ffffff;
    --surface-50: #f3f2f1;
    --surface-100: #e1dfdd;
    --surface-200: #bebbb8;
    --surface-300: #a19f9d;
    --surface-400: #797775;
    --surface-500: #484644;
    --surface-600: #323130;
    --surface-700: #252423;
    --surface-800: #1b1a19;
    --surface-900: #11100f;
    --gray-50: #f3f2f1;
    --gray-100: #e1dfdd;
    --gray-200: #bebbb8;
    --gray-300: #a19f9d;
    --gray-400: #797775;
    --gray-500: #484644;
    --gray-600: #323130;
    --gray-700: #252423;
    --gray-800: #1b1a19;
    --gray-900: #11100f;
    --content-padding:1rem;
    --inline-spacing:0.5rem;
    --border-radius:2px;
    --surface-ground:#faf9f8;
    --surface-section:#ffffff;
    --surface-card:#ffffff;
    --surface-overlay:#ffffff;
    --surface-border:#edebe9;
    --surface-hover:#f3f2f1;
    --maskbg: rgba(0, 0, 0, 0.4);
    --focus-ring: inset 0 0 0 1px #605e5c;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .p-component {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: normal;
  }
  
  .p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    transition-duration: 0.2s;
  }
  
  .p-disabled, .p-component:disabled {
    opacity: 0.6;
  }
  
  .p-error {
    color: #a4252c;
  }
  
  .p-text-secondary {
    color: #605e5c;
  }
  
  .pi {
    font-size: 1rem;
  }
  
  .p-link {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border-radius: 2px;
  }
  .p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  
  .p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
  }
  
  .p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
  }
  
  @keyframes p-component-overlay-enter-animation {
    from {
      background-color: transparent;
    }
    to {
      background-color: var(--maskbg);
    }
  }
  @keyframes p-component-overlay-leave-animation {
    from {
      background-color: var(--maskbg);
    }
    to {
      background-color: transparent;
    }
  }
  
  :root {
    --blue-50:#f3f8fc;
    --blue-100:#c6dcef;
    --blue-200:#98c1e3;
    --blue-300:#6ba5d7;
    --blue-400:#3d8aca;
    --blue-500:#106ebe;
    --blue-600:#0e5ea2;
    --blue-700:#0b4d85;
    --blue-800:#093d69;
    --blue-900:#062c4c;
    --green-50:#f7fbf3;
    --green-100:#d9eac4;
    --green-200:#bbda96;
    --green-300:#9cca67;
    --green-400:#7eb939;
    --green-500:#60a90a;
    --green-600:#529009;
    --green-700:#437607;
    --green-800:#355d06;
    --green-900:#264404;
    --yellow-50:#fffcf2;
    --yellow-100:#ffeec2;
    --yellow-200:#ffe191;
    --yellow-300:#ffd461;
    --yellow-400:#ffc630;
    --yellow-500:#ffb900;
    --yellow-600:#d99d00;
    --yellow-700:#b38200;
    --yellow-800:#8c6600;
    --yellow-900:#664a00;
    --cyan-50:#f2fbfc;
    --cyan-100:#c2eef1;
    --cyan-200:#91e0e5;
    --cyan-300:#61d2da;
    --cyan-400:#30c5ce;
    --cyan-500:#00b7c3;
    --cyan-600:#009ca6;
    --cyan-700:#008089;
    --cyan-800:#00656b;
    --cyan-900:#00494e;
    --pink-50:#fef2f9;
    --pink-100:#f8c2e3;
    --pink-200:#f391ce;
    --pink-300:#ee61b8;
    --pink-400:#e830a2;
    --pink-500:#e3008c;
    --pink-600:#c10077;
    --pink-700:#9f0062;
    --pink-800:#7d004d;
    --pink-900:#5b0038;
    --indigo-50:#f5f6fc;
    --indigo-100:#cdd3f1;
    --indigo-200:#a5b0e6;
    --indigo-300:#7d8edc;
    --indigo-400:#566bd1;
    --indigo-500:#2e48c6;
    --indigo-600:#273da8;
    --indigo-700:#20328b;
    --indigo-800:#19286d;
    --indigo-900:#121d4f;
    --teal-50:#f2f9f8;
    --teal-100:#c2e1dd;
    --teal-200:#91c9c2;
    --teal-300:#61b2a8;
    --teal-400:#309a8d;
    --teal-500:#008272;
    --teal-600:#006f61;
    --teal-700:#005b50;
    --teal-800:#00483f;
    --teal-900:#00342e;
    --orange-50:#fdf7f2;
    --orange-100:#f5d8c2;
    --orange-200:#edb991;
    --orange-300:#e49a61;
    --orange-400:#dc7b30;
    --orange-500:#d45c00;
    --orange-600:#b44e00;
    --orange-700:#944000;
    --orange-800:#753300;
    --orange-900:#552500;
    --bluegray-50:#f8f9fb;
    --bluegray-100:#dee4ed;
    --bluegray-200:#c4cfe0;
    --bluegray-300:#a9bad2;
    --bluegray-400:#8fa4c4;
    --bluegray-500:#758fb6;
    --bluegray-600:#637a9b;
    --bluegray-700:#52647f;
    --bluegray-800:#404f64;
    --bluegray-900:#2f3949;
    --purple-50:#f9f8fd;
    --purple-100:#e1dff7;
    --purple-200:#cac5f1;
    --purple-300:#b2abeb;
    --purple-400:#9b92e4;
    --purple-500:#8378de;
    --purple-600:#6f66bd;
    --purple-700:#5c549b;
    --purple-800:#48427a;
    --purple-900:#343059;
  }
  
  .p-autocomplete .p-autocomplete-loader {
    right: 0.5rem;
  }
  .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 2.857rem;
  }
  .p-autocomplete .p-autocomplete-multiple-container {
    padding: 0.25rem 0.5rem;
  }
  .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
    border-color: #323130;
  }
  .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
    border-color: #9A294B;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0.25rem 0;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #323130;
    padding: 0;
    margin: 0;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #edebe9;
    color: #323130;
    border-radius: 2px;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    margin-left: 0.5rem;
  }
  .p-autocomplete.p-invalid.p-component > .p-inputtext {
    border-color: #a4252c;
  }
  
  .p-autocomplete-panel {
    background: #ffffff;
    color: #323130;
    border: 0 none;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
  }
  .p-autocomplete-panel .p-autocomplete-items {
    padding: 0;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    margin: 0;
    padding: 0.75rem 0.5rem;
    border: 1px solid transparent;
    color: #323130;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    color: #323130;
    background: #f3f2f1;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: #323130;
    background: #edebe9;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    margin: 0;
    padding: 0.75rem 0.5rem;
    color: #9A294B;
    background: #ffffff;
    font-weight: 600;
  }
  
  .p-calendar.p-invalid.p-component > .p-inputtext {
    border-color: #a4252c;
  }
  
  .p-datepicker {
    padding: 0.75rem;
    background: #ffffff;
    color: #323130;
    border: 1px solid #605e5c;
    border-radius: 2px;
  }
  .p-datepicker:not(.p-datepicker-inline) {
    background: #ffffff;
    border: 0 none;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
  }
  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #ffffff;
  }
  .p-datepicker .p-datepicker-header {
    padding: 0 0.5rem 0.5rem 0.5rem;
    color: #323130;
    background: #ffffff;
    font-weight: 600;
    margin: 0;
    border-bottom: 0 none;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev,
  .p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
  .p-datepicker .p-datepicker-header .p-datepicker-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title select {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
    border-color: #9A294B;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem;
  }
  .p-datepicker table {
    font-size: 1rem;
    margin: 0;
  }
  .p-datepicker table th {
    padding: 0.5rem;
  }
  .p-datepicker table th > span {
    width: 2rem;
    height: 2rem;
  }
  .p-datepicker table td {
    padding: 1px;
  }
  .p-datepicker table td > span {
    width: 2rem;
    height: 2rem;
    border-radius: 2px;
    transition: box-shadow 0.2s;
    border: 0 none;
  }
  .p-datepicker table td > span.p-highlight {
    color: #323130;
    background: #edebe9;
  }
  .p-datepicker table td > span:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-datepicker table td.p-datepicker-today > span {
    background: #9A294B;
    color: #ffffff;
    border-color: transparent;
  }
  .p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #323130;
    background: #edebe9;
  }
  .p-datepicker .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid #edebe9;
  }
  .p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
  }
  .p-datepicker .p-timepicker {
    border-top: 1px solid #edebe9;
    padding: 0.5rem;
  }
  .p-datepicker .p-timepicker button {
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-datepicker .p-timepicker button:enabled:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .p-datepicker .p-timepicker button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-datepicker .p-timepicker button:last-child {
    margin-top: 0.2em;
  }
  .p-datepicker .p-timepicker span {
    font-size: 1.25rem;
  }
  .p-datepicker .p-timepicker > div {
    padding: 0 0.5rem;
  }
  .p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
  }
  .p-datepicker .p-monthpicker {
    margin: 0;
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 1px;
    transition: box-shadow 0.2s;
    border-radius: 2px;
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #323130;
    background: #edebe9;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-right: 1px solid #edebe9;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
    border-right: 0 none;
  }
  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #f3f2f1;
  }
  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
    background: #f3f2f1;
  }
  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  
  @media screen and (max-width: 769px) {
    .p-datepicker table th, .p-datepicker table td {
      padding: 0;
    }
  }
  .p-cascadeselect {
    background: #ffffff;
    border: 1px solid #605e5c;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 2px;
  }
  .p-cascadeselect:not(.p-disabled):hover {
    border-color: #323130;
  }
  .p-cascadeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
    border-color: #9A294B;
  }
  .p-cascadeselect .p-cascadeselect-label {
    background: transparent;
    border: 0 none;
    padding: 0.5rem 0.5rem;
  }
  .p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: #605e5c;
  }
  .p-cascadeselect .p-cascadeselect-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }
  .p-cascadeselect .p-cascadeselect-trigger {
    background: transparent;
    color: #605e5c;
    width: 2.357rem;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .p-cascadeselect.p-invalid.p-component {
    border-color: #a4252c;
  }
  
  .p-cascadeselect-panel {
    background: #ffffff;
    color: #323130;
    border: 0 none;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
  }
  .p-cascadeselect-panel .p-cascadeselect-items {
    padding: 0;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    margin: 0;
    border: 1px solid transparent;
    color: #323130;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
    padding: 0.75rem 0.5rem;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
    color: #323130;
    background: #edebe9;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #323130;
    background: #f3f2f1;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
    font-size: 0.875rem;
  }
  
  .p-input-filled .p-cascadeselect {
    background: #faf9f8;
  }
  .p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: #faf9f8;
  }
  .p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
    background-color: #faf9f8;
  }
  
  .p-checkbox {
    width: 20px;
    height: 20px;
  }
  .p-checkbox .p-checkbox-box {
    border: 1px solid #605e5c;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #323130;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 14px;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #9A294B;
    background: #9A294B;
  }
  .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: #005a9e;
    background: #005a9e;
    color: #ffffff;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #323130;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
    border-color: #9A294B;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #005a9e;
    background: #005a9e;
    color: #ffffff;
  }
  .p-checkbox.p-invalid > .p-checkbox-box {
    border-color: #a4252c;
  }
  
  .p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #faf9f8;
  }
  .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: #9A294B;
  }
  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #faf9f8;
  }
  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #005a9e;
  }
  
  .p-chips .p-chips-multiple-container {
    padding: 0.25rem 0.5rem;
  }
  .p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: #323130;
  }
  .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
    border-color: #9A294B;
  }
  .p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #edebe9;
    color: #323130;
    border-radius: 2px;
  }
  .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: 0.5rem;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0.25rem 0;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #323130;
    padding: 0;
    margin: 0;
  }
  .p-chips.p-invalid.p-component > .p-inputtext {
    border-color: #a4252c;
  }
  
  .p-colorpicker-preview {
    width: 2rem;
    height: 2rem;
  }
  
  .p-colorpicker-panel {
    background: #323232;
    border-color: #191919;
  }
  .p-colorpicker-panel .p-colorpicker-color-handle,
  .p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #ffffff;
  }
  
  .p-colorpicker-overlay-panel {
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
  }
  
  .p-dropdown {
    background: #ffffff;
    border: 1px solid #605e5c;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 2px;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: #323130;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
    border-color: #9A294B;
  }
  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.5rem;
  }
  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
  }
  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #605e5c;
  }
  .p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }
  .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #605e5c;
    width: 2.357rem;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .p-dropdown .p-dropdown-clear-icon {
    color: #605e5c;
    right: 2.357rem;
  }
  .p-dropdown.p-invalid.p-component {
    border-color: #a4252c;
  }
  
  .p-dropdown-panel {
    background: #ffffff;
    color: #323130;
    border: 0 none;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
  }
  .p-dropdown-panel .p-dropdown-header {
    padding: 0.75rem 0.5rem;
    border-bottom: 1px solid #edebe9;
    color: #323130;
    background: #ffffff;
    margin: 0;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.5rem;
    margin-right: -1.5rem;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.5rem;
    color: #605e5c;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter {
    padding-right: 3rem;
    margin-right: -3rem;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter-clear-icon {
    right: 2rem;
  }
  .p-dropdown-panel .p-dropdown-items {
    padding: 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.75rem 0.5rem;
    border: 1px solid transparent;
    color: #323130;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #323130;
    background: #edebe9;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #323130;
    background: #f3f2f1;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.75rem 0.5rem;
    color: #9A294B;
    background: #ffffff;
    font-weight: 600;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.75rem 0.5rem;
    color: #323130;
    background: transparent;
  }
  
  .p-input-filled .p-dropdown {
    background: #faf9f8;
  }
  .p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #faf9f8;
  }
  .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: #faf9f8;
  }
  
  .p-editor-container .p-editor-toolbar {
    background: #faf9f8;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid #a19f9d;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
    stroke: #605e5c;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
    fill: #605e5c;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
    border: 0 none;
    color: #605e5c;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
    color: #323130;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
    stroke: #323130;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
    fill: #323130;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #323130;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #323130;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: #323130;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: #ffffff;
    border: 0 none;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
    border-radius: 2px;
    padding: 0;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    color: #323130;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
    color: #323130;
    background: #f3f2f1;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
    padding: 0.75rem 0.5rem;
  }
  .p-editor-container .p-editor-content {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .p-editor-container .p-editor-content.ql-snow {
    border: 1px solid #a19f9d;
  }
  .p-editor-container .p-editor-content .ql-editor {
    background: #ffffff;
    color: #323130;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .p-editor-container .ql-snow.ql-toolbar button:hover,
  .p-editor-container .ql-snow.ql-toolbar button:focus {
    color: #323130;
  }
  .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: #323130;
  }
  .p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: #323130;
  }
  .p-editor-container .ql-snow.ql-toolbar button.ql-active,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: #9A294B;
  }
  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: #9A294B;
  }
  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: #9A294B;
  }
  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
    color: #9A294B;
  }
  
  .p-inputgroup-addon {
    background: #f3f2f1;
    color: #605e5c;
    border-top: 1px solid #605e5c;
    border-left: 1px solid #605e5c;
    border-bottom: 1px solid #605e5c;
    padding: 0.5rem 0.5rem;
    min-width: 2.357rem;
  }
  .p-inputgroup-addon:last-child {
    border-right: 1px solid #605e5c;
  }
  
  .p-inputgroup > .p-component,
  .p-inputgroup > .p-inputwrapper > .p-inputtext,
  .p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
  }
  .p-inputgroup > .p-component + .p-inputgroup-addon,
  .p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
  .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none;
  }
  .p-inputgroup > .p-component:focus,
  .p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
  .p-inputgroup > .p-float-label > .p-component:focus {
    z-index: 1;
  }
  .p-inputgroup > .p-component:focus ~ label,
  .p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
  .p-inputgroup > .p-float-label > .p-component:focus ~ label {
    z-index: 1;
  }
  
  .p-inputgroup-addon:first-child,
  .p-inputgroup button:first-child,
  .p-inputgroup input:first-child,
  .p-inputgroup > .p-inputwrapper:first-child,
  .p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  
  .p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  
  .p-inputgroup-addon:last-child,
  .p-inputgroup button:last-child,
  .p-inputgroup input:last-child,
  .p-inputgroup > .p-inputwrapper:last-child,
  .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  .p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  .p-fluid .p-inputgroup .p-button {
    width: auto;
  }
  .p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.357rem;
  }
  
  .p-inputnumber.p-invalid.p-component > .p-inputtext {
    border-color: #a4252c;
  }
  
  .p-inputswitch {
    width: 3rem;
    height: 1.75rem;
  }
  .p-inputswitch .p-inputswitch-slider {
    background: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 30px;
  }
  .p-inputswitch .p-inputswitch-slider:before {
    background: #605e5c;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.25rem;
    margin-top: -0.625rem;
    border-radius: 50%;
    transition-duration: 0.2s;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem);
  }
  .p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #ffffff;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #9A294B;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #ffffff;
  }
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #005a9e;
  }
  .p-inputswitch.p-invalid {
    border-color: #a4252c;
  }
  
  .p-inputtext {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #323130;
    background: #ffffff;
    padding: 0.5rem 0.5rem;
    border: 1px solid #605e5c;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 2px;
  }
  .p-inputtext:enabled:hover {
    border-color: #323130;
  }
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
    border-color: #9A294B;
  }
  .p-inputtext.p-invalid.p-component {
    border-color: #a4252c;
  }
  .p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
  }
  .p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 0.625rem 0.625rem;
  }
  
  .p-float-label > label {
    left: 0.5rem;
    color: #605e5c;
    transition-duration: 0.2s;
  }
  
  .p-float-label > label.p-error {
    color: #a4252c;
  }
  
  .p-input-icon-left > i:first-of-type,
  .p-input-icon-left > svg:first-of-type,
  .p-input-icon-left > .p-input-prefix {
    left: 0.5rem;
    color: #605e5c;
  }
  
  .p-input-icon-left > .p-inputtext {
    padding-left: 2rem;
  }
  
  .p-input-icon-left.p-float-label > label {
    left: 2rem;
  }
  
  .p-input-icon-right > i:last-of-type,
  .p-input-icon-right > svg:last-of-type,
  .p-input-icon-right > .p-input-suffix {
    right: 0.5rem;
    color: #605e5c;
  }
  
  .p-input-icon-right > .p-inputtext {
    padding-right: 2rem;
  }
  
  ::-webkit-input-placeholder {
    color: #605e5c;
  }
  
  :-moz-placeholder {
    color: #605e5c;
  }
  
  ::-moz-placeholder {
    color: #605e5c;
  }
  
  :-ms-input-placeholder {
    color: #605e5c;
  }
  
  .p-input-filled .p-inputtext {
    background-color: #faf9f8;
  }
  .p-input-filled .p-inputtext:enabled:hover {
    background-color: #faf9f8;
  }
  .p-input-filled .p-inputtext:enabled:focus {
    background-color: #faf9f8;
  }
  
  .p-inputtext-sm .p-inputtext {
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
  }
  
  .p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: 0.625rem 0.625rem;
  }
  
  .p-listbox {
    background: #ffffff;
    color: #323130;
    border: 1px solid #605e5c;
    border-radius: 2px;
  }
  .p-listbox .p-listbox-header {
    padding: 0.75rem 0.5rem;
    border-bottom: 1px solid #edebe9;
    color: #323130;
    background: #ffffff;
    margin: 0;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.5rem;
  }
  .p-listbox .p-listbox-header .p-listbox-filter-icon {
    right: 0.5rem;
    color: #605e5c;
  }
  .p-listbox .p-listbox-list {
    padding: 0;
  }
  .p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 0.75rem 0.5rem;
    border: 1px solid transparent;
    color: #323130;
    transition: box-shadow 0.2s;
    border-radius: 0;
  }
  .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #323130;
    background: #edebe9;
  }
  .p-listbox .p-listbox-list .p-listbox-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-listbox .p-listbox-list .p-listbox-item-group {
    margin: 0;
    padding: 0.75rem 0.5rem;
    color: #9A294B;
    background: #ffffff;
    font-weight: 600;
  }
  .p-listbox .p-listbox-list .p-listbox-empty-message {
    padding: 0.75rem 0.5rem;
    color: #323130;
    background: transparent;
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #323130;
    background: #f3f2f1;
  }
  .p-listbox.p-invalid {
    border-color: #a4252c;
  }
  
  .p-mention-panel {
    background: #ffffff;
    color: #323130;
    border: 0 none;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
  }
  .p-mention-panel .p-mention-items {
    padding: 0;
  }
  .p-mention-panel .p-mention-items .p-mention-item {
    margin: 0;
    padding: 0.75rem 0.5rem;
    border: 1px solid transparent;
    color: #323130;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
  }
  .p-mention-panel .p-mention-items .p-mention-item:hover {
    color: #323130;
    background: #f3f2f1;
  }
  .p-mention-panel .p-mention-items .p-mention-item.p-highlight {
    color: #323130;
    background: #edebe9;
  }
  
  .p-multiselect {
    background: #ffffff;
    border: 1px solid #605e5c;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 2px;
  }
  .p-multiselect:not(.p-disabled):hover {
    border-color: #323130;
  }
  .p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
    border-color: #9A294B;
  }
  .p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.5rem;
  }
  .p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-multiselect .p-multiselect-label.p-placeholder {
    color: #605e5c;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #edebe9;
    color: #323130;
    border-radius: 2px;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: 0.5rem;
  }
  .p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: #605e5c;
    width: 2.357rem;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .p-multiselect .p-multiselect-clear-icon {
    color: #605e5c;
    right: 2.357rem;
  }
  .p-multiselect.p-invalid.p-component {
    border-color: #a4252c;
  }
  
  .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.25rem 0.5rem;
  }
  .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
    padding: 0.5rem 0.5rem;
  }
  .p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.5rem;
  }
  
  .p-multiselect-panel {
    background: #ffffff;
    color: #323130;
    border: 0 none;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
  }
  .p-multiselect-panel .p-multiselect-header {
    padding: 0.75rem 0.5rem;
    border-bottom: 1px solid #edebe9;
    color: #323130;
    background: #ffffff;
    margin: 0;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
    margin: 0 0.5rem;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.5rem;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    right: 0.5rem;
    color: #605e5c;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-multiselect-panel .p-multiselect-items {
    padding: 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.75rem 0.5rem;
    border: 1px solid transparent;
    color: #323130;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #323130;
    background: #edebe9;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #323130;
    background: #f3f2f1;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    margin: 0;
    padding: 0.75rem 0.5rem;
    color: #9A294B;
    background: #ffffff;
    font-weight: 600;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: 0.75rem 0.5rem;
    color: #323130;
    background: transparent;
  }
  
  .p-input-filled .p-multiselect {
    background: #faf9f8;
  }
  .p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #faf9f8;
  }
  .p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: #faf9f8;
  }
  
  .p-password.p-invalid.p-component > .p-inputtext {
    border-color: #a4252c;
  }
  
  .p-password-panel {
    padding: 1rem;
    background: #ffffff;
    color: #323130;
    border: 0 none;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
    border-radius: 2px;
  }
  .p-password-panel .p-password-meter {
    margin-bottom: 0.5rem;
    background: #edebe9;
  }
  .p-password-panel .p-password-meter .p-password-strength.weak {
    background: #d13438;
  }
  .p-password-panel .p-password-meter .p-password-strength.medium {
    background: #ffaa44;
  }
  .p-password-panel .p-password-meter .p-password-strength.strong {
    background: #498205;
  }
  
  .p-radiobutton {
    width: 20px;
    height: 20px;
  }
  .p-radiobutton .p-radiobutton-box {
    border: 1px solid #605e5c;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #323130;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #323130;
  }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
    border-color: #9A294B;
  }
  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 12px;
    height: 12px;
    transition-duration: 0.2s;
    background-color: #9A294B;
  }
  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #9A294B;
    background: #ffffff;
  }
  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #9A294B;
    background: #ffffff;
    color: #9A294B;
  }
  .p-radiobutton.p-invalid > .p-radiobutton-box {
    border-color: #a4252c;
  }
  .p-radiobutton:focus {
    outline: 0 none;
  }
  
  .p-input-filled .p-radiobutton .p-radiobutton-box {
    background-color: #faf9f8;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #faf9f8;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: #ffffff;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: #ffffff;
  }
  
  .p-rating .p-rating-icon {
    color: #323130;
    margin-left: 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    font-size: 1.143rem;
  }
  .p-rating .p-rating-icon.p-rating-cancel {
    color: #a4252c;
  }
  .p-rating .p-rating-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-rating .p-rating-icon:first-child {
    margin-left: 0;
  }
  .p-rating .p-rating-icon.pi-star-fill {
    color: #323130;
  }
  .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
    color: #9A294B;
  }
  .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
    color: #a4252c;
  }
  
  .p-selectbutton .p-button {
    background: #ffffff;
    border: 1px solid #605e5c;
    color: #323130;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-selectbutton .p-button .p-button-icon-left,
  .p-selectbutton .p-button .p-button-icon-right {
    color: #605e5c;
  }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #f3f2f1;
    border-color: #605e5c;
    color: #323130;
  }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #605e5c;
  }
  .p-selectbutton .p-button.p-highlight {
    background: #edebe9;
    border-color: #605e5c;
    color: #323130;
  }
  .p-selectbutton .p-button.p-highlight .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #323130;
  }
  .p-selectbutton .p-button.p-highlight:hover {
    background: #edebe9;
    border-color: #605e5c;
    color: #323130;
  }
  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #323130;
  }
  .p-selectbutton.p-invalid > .p-button {
    border-color: #a4252c;
  }
  
  .p-slider {
    background: #c8c6c4;
    border: 0 none;
    border-radius: 2px;
  }
  .p-slider.p-slider-horizontal {
    height: 4px;
  }
  .p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -8px;
    margin-left: -8px;
  }
  .p-slider.p-slider-vertical {
    width: 4px;
  }
  .p-slider.p-slider-vertical .p-slider-handle {
    margin-left: -8px;
    margin-bottom: -8px;
  }
  .p-slider .p-slider-handle {
    height: 16px;
    width: 16px;
    background: #ffffff;
    border: 2px solid #605e5c;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-slider .p-slider-handle:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-slider .p-slider-range {
    background: #605e5c;
  }
  .p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #ffffff;
    border-color: #005a9e;
  }
  
  .p-treeselect {
    background: #ffffff;
    border: 1px solid #605e5c;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 2px;
  }
  .p-treeselect:not(.p-disabled):hover {
    border-color: #323130;
  }
  .p-treeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
    border-color: #9A294B;
  }
  .p-treeselect .p-treeselect-label {
    padding: 0.5rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-treeselect .p-treeselect-label.p-placeholder {
    color: #605e5c;
  }
  .p-treeselect.p-treeselect-chip .p-treeselect-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #edebe9;
    color: #323130;
    border-radius: 16px;
  }
  .p-treeselect .p-treeselect-trigger {
    background: transparent;
    color: #605e5c;
    width: 2.357rem;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .p-treeselect.p-invalid.p-component {
    border-color: #a4252c;
  }
  
  .p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: 0.25rem 0.5rem;
  }
  
  .p-treeselect-panel {
    background: #ffffff;
    color: #323130;
    border: 0 none;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
  }
  .p-treeselect-panel .p-treeselect-header {
    padding: 0.75rem 0.5rem;
    border-bottom: 1px solid #edebe9;
    color: #323130;
    background: #ffffff;
    margin: 0;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
    margin-right: 0.5rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
    padding-right: 1.5rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
    right: 0.5rem;
    color: #605e5c;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
    padding-right: 3rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
    right: 2rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-close {
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
    border: 0 none;
  }
  .p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
    padding: 0.75rem 0.5rem;
    color: #323130;
    background: transparent;
  }
  
  .p-input-filled .p-treeselect {
    background: #faf9f8;
  }
  .p-input-filled .p-treeselect:not(.p-disabled):hover {
    background-color: #faf9f8;
  }
  .p-input-filled .p-treeselect:not(.p-disabled).p-focus {
    background-color: #faf9f8;
  }
  
  .p-togglebutton.p-button {
    background: #ffffff;
    border: 1px solid #605e5c;
    color: #323130;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-togglebutton.p-button .p-button-icon-left,
  .p-togglebutton.p-button .p-button-icon-right {
    color: #605e5c;
  }
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #f3f2f1;
    border-color: #605e5c;
    color: #323130;
  }
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #605e5c;
  }
  .p-togglebutton.p-button.p-highlight {
    background: #edebe9;
    border-color: #605e5c;
    color: #323130;
  }
  .p-togglebutton.p-button.p-highlight .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: #323130;
  }
  .p-togglebutton.p-button.p-highlight:hover {
    background: #edebe9;
    border-color: #605e5c;
    color: #323130;
  }
  .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: #323130;
  }
  .p-togglebutton.p-button.p-invalid > .p-button {
    border-color: #a4252c;
  }
  
  .p-button {
    color: #ffffff;
    background: #9A294B;
    border: 1px solid #9A294B;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 2px;
  }
  .p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
    background: #81233f;
    color: #ffffff;
    border-color: #81233f;
  }
  .p-button:enabled:active, .p-button:not(button):not(a):not(.p-disabled):active {
    background: #9A294B;
    color: #ffffff;
    border-color: #9A294B;
  }
  .p-button.p-button-outlined {
    background-color: transparent;
    color: #9A294B;
    border: 1px solid;
  }
  .p-button.p-button-outlined:enabled:hover, .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 120, 212, 0.04);
    color: #9A294B;
    border: 1px solid;
  }
  .p-button.p-button-outlined:enabled:active, .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 120, 212, 0.16);
    color: #9A294B;
    border: 1px solid;
  }
  .p-button.p-button-outlined.p-button-plain {
    color: #605e5c;
    border-color: #605e5c;
  }
  .p-button.p-button-outlined.p-button-plain:enabled:hover, .p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):hover {
    background: #f3f2f1;
    color: #605e5c;
  }
  .p-button.p-button-outlined.p-button-plain:enabled:active, .p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):active {
    background: #edebe9;
    color: #605e5c;
  }
  .p-button.p-button-text {
    background-color: transparent;
    color: #9A294B;
    border-color: transparent;
  }
  .p-button.p-button-text:enabled:hover, .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 120, 212, 0.04);
    color: #9A294B;
    border-color: transparent;
  }
  .p-button.p-button-text:enabled:active, .p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 120, 212, 0.16);
    color: #9A294B;
    border-color: transparent;
  }
  .p-button.p-button-text.p-button-plain {
    color: #605e5c;
  }
  .p-button.p-button-text.p-button-plain:enabled:hover, .p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):hover {
    background: #f3f2f1;
    color: #605e5c;
  }
  .p-button.p-button-text.p-button-plain:enabled:active, .p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):active {
    background: #edebe9;
    color: #605e5c;
  }
  .p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-button .p-button-icon-left {
    margin-right: 0.5rem;
  }
  .p-button .p-button-icon-right {
    margin-left: 0.5rem;
  }
  .p-button .p-button-icon-bottom {
    margin-top: 0.5rem;
  }
  .p-button .p-button-icon-top {
    margin-bottom: 0.5rem;
  }
  .p-button .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: #9A294B;
    background-color: #ffffff;
  }
  .p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .p-button.p-button-rounded {
    border-radius: 2rem;
  }
  .p-button.p-button-icon-only {
    width: 2.357rem;
    padding: 0.5rem 0;
  }
  .p-button.p-button-icon-only .p-button-icon-left,
  .p-button.p-button-icon-only .p-button-icon-right {
    margin: 0;
  }
  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.357rem;
  }
  .p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
  }
  .p-button.p-button-sm .p-button-icon {
    font-size: 0.875rem;
  }
  .p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.625rem 1.25rem;
  }
  .p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem;
  }
  .p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
    margin-left: 0.5rem;
  }
  .p-button.p-button-loading-label-only.p-button-loading-right .p-button-label {
    margin-right: 0.5rem;
  }
  .p-button.p-button-loading-label-only.p-button-loading-top .p-button-label {
    margin-top: 0.5rem;
  }
  .p-button.p-button-loading-label-only.p-button-loading-bottom .p-button-label {
    margin-bottom: 0.5rem;
  }
  .p-button.p-button-loading-label-only .p-button-loading-icon {
    margin: 0;
  }
  
  .p-fluid .p-button {
    width: 100%;
  }
  .p-fluid .p-button-icon-only {
    width: 2.357rem;
  }
  .p-fluid .p-buttonset {
    display: flex;
  }
  .p-fluid .p-buttonset .p-button {
    flex: 1;
  }
  
  .p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button, .p-fileupload-choose.p-button-secondary {
    color: #ffffff;
    background: #d45c00;
    border: 1px solid #d45c00;
  }
  .p-button.p-button-secondary:enabled:hover, .p-button.p-button-secondary:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary:enabled:hover, .p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):hover {
    background: #bf5300;
    color: #ffffff;
    border-color: #bf5300;
  }
  .p-button.p-button-secondary:enabled:focus, .p-button.p-button-secondary:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-secondary:enabled:focus, .p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #ffbc88;
  }
  .p-button.p-button-secondary:enabled:active, .p-button.p-button-secondary:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary:enabled:active, .p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):active {
    background: #aa4a00;
    color: #ffffff;
    border-color: #aa4a00;
  }
  .p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined, .p-fileupload-choose.p-button-secondary.p-button-outlined {
    background-color: transparent;
    color: #d45c00;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(212, 92, 0, 0.04);
    color: #d45c00;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-outlined:enabled:active, .p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(212, 92, 0, 0.16);
    color: #d45c00;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text, .p-fileupload-choose.p-button-secondary.p-button-text {
    background-color: transparent;
    color: #d45c00;
    border-color: transparent;
  }
  .p-button.p-button-secondary.p-button-text:enabled:hover, .p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-text:enabled:hover, .p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(212, 92, 0, 0.04);
    border-color: transparent;
    color: #d45c00;
  }
  .p-button.p-button-secondary.p-button-text:enabled:active, .p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary.p-button-text:enabled:active, .p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(212, 92, 0, 0.16);
    border-color: transparent;
    color: #d45c00;
  }
  
  .p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button, .p-fileupload-choose.p-button-info {
    color: #ffffff;
    background: #00b7c3;
    border: 1px solid #00b7c3;
  }
  .p-button.p-button-info:enabled:hover, .p-button.p-button-info:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info:enabled:hover, .p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):hover {
    background: #00a5b0;
    color: #ffffff;
    border-color: #00a5b0;
  }
  .p-button.p-button-info:enabled:focus, .p-button.p-button-info:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-info:enabled:focus, .p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #81f7ff;
  }
  .p-button.p-button-info:enabled:active, .p-button.p-button-info:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info:enabled:active, .p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):active {
    background: #00929c;
    color: #ffffff;
    border-color: #00929c;
  }
  .p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined, .p-fileupload-choose.p-button-info.p-button-outlined {
    background-color: transparent;
    color: #00b7c3;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-outlined:enabled:hover, .p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 183, 195, 0.04);
    color: #00b7c3;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-outlined:enabled:active, .p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 183, 195, 0.16);
    color: #00b7c3;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text, .p-fileupload-choose.p-button-info.p-button-text {
    background-color: transparent;
    color: #00b7c3;
    border-color: transparent;
  }
  .p-button.p-button-info.p-button-text:enabled:hover, .p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info.p-button-text:enabled:hover, .p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 183, 195, 0.04);
    border-color: transparent;
    color: #00b7c3;
  }
  .p-button.p-button-info.p-button-text:enabled:active, .p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info.p-button-text:enabled:active, .p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 183, 195, 0.16);
    border-color: transparent;
    color: #00b7c3;
  }
  
  .p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button, .p-fileupload-choose.p-button-success {
    color: #ffffff;
    background: #498205;
    border: 1px solid #498205;
  }
  .p-button.p-button-success:enabled:hover, .p-button.p-button-success:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success:enabled:hover, .p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):hover {
    background: #427505;
    color: #ffffff;
    border-color: #427505;
  }
  .p-button.p-button-success:enabled:focus, .p-button.p-button-success:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-success:enabled:focus, .p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #baf96f;
  }
  .p-button.p-button-success:enabled:active, .p-button.p-button-success:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success:enabled:active, .p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):active {
    background: #3a6804;
    color: #ffffff;
    border-color: #3a6804;
  }
  .p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined, .p-fileupload-choose.p-button-success.p-button-outlined {
    background-color: transparent;
    color: #498205;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-outlined:enabled:hover, .p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(73, 130, 5, 0.04);
    color: #498205;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-outlined:enabled:active, .p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(73, 130, 5, 0.16);
    color: #498205;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text, .p-fileupload-choose.p-button-success.p-button-text {
    background-color: transparent;
    color: #498205;
    border-color: transparent;
  }
  .p-button.p-button-success.p-button-text:enabled:hover, .p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success.p-button-text:enabled:hover, .p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(73, 130, 5, 0.04);
    border-color: transparent;
    color: #498205;
  }
  .p-button.p-button-success.p-button-text:enabled:active, .p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success.p-button-text:enabled:active, .p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(73, 130, 5, 0.16);
    border-color: transparent;
    color: #498205;
  }
  
  .p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button, .p-fileupload-choose.p-button-warning {
    color: #323130;
    background: #ffaa44;
    border: 1px solid #ffaa44;
  }
  .p-button.p-button-warning:enabled:hover, .p-button.p-button-warning:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning:enabled:hover, .p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):hover {
    background: #ff9b24;
    color: #323130;
    border-color: #ff9b24;
  }
  .p-button.p-button-warning:enabled:focus, .p-button.p-button-warning:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-warning:enabled:focus, .p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #ffddb4;
  }
  .p-button.p-button-warning:enabled:active, .p-button.p-button-warning:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning:enabled:active, .p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):active {
    background: #ff8d03;
    color: #323130;
    border-color: #ff8d03;
  }
  .p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined, .p-fileupload-choose.p-button-warning.p-button-outlined {
    background-color: transparent;
    color: #ffaa44;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-outlined:enabled:hover, .p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(255, 170, 68, 0.04);
    color: #ffaa44;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-outlined:enabled:active, .p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(255, 170, 68, 0.16);
    color: #ffaa44;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text, .p-fileupload-choose.p-button-warning.p-button-text {
    background-color: transparent;
    color: #ffaa44;
    border-color: transparent;
  }
  .p-button.p-button-warning.p-button-text:enabled:hover, .p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning.p-button-text:enabled:hover, .p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(255, 170, 68, 0.04);
    border-color: transparent;
    color: #ffaa44;
  }
  .p-button.p-button-warning.p-button-text:enabled:active, .p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning.p-button-text:enabled:active, .p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(255, 170, 68, 0.16);
    border-color: transparent;
    color: #ffaa44;
  }
  
  .p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button, .p-fileupload-choose.p-button-help {
    color: #ffffff;
    background: #8378de;
    border: 1px solid #8378de;
  }
  .p-button.p-button-help:enabled:hover, .p-button.p-button-help:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help:enabled:hover, .p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):hover {
    background: #6a5dd7;
    color: #ffffff;
    border-color: #6a5dd7;
  }
  .p-button.p-button-help:enabled:focus, .p-button.p-button-help:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-help:enabled:focus, .p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #cdc9f2;
  }
  .p-button.p-button-help:enabled:active, .p-button.p-button-help:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help:enabled:active, .p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):active {
    background: #5141d1;
    color: #ffffff;
    border-color: #5141d1;
  }
  .p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined, .p-fileupload-choose.p-button-help.p-button-outlined {
    background-color: transparent;
    color: #8378de;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-outlined:enabled:hover, .p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(131, 120, 222, 0.04);
    color: #8378de;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-outlined:enabled:active, .p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(131, 120, 222, 0.16);
    color: #8378de;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text, .p-fileupload-choose.p-button-help.p-button-text {
    background-color: transparent;
    color: #8378de;
    border-color: transparent;
  }
  .p-button.p-button-help.p-button-text:enabled:hover, .p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help.p-button-text:enabled:hover, .p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(131, 120, 222, 0.04);
    border-color: transparent;
    color: #8378de;
  }
  .p-button.p-button-help.p-button-text:enabled:active, .p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help.p-button-text:enabled:active, .p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(131, 120, 222, 0.16);
    border-color: transparent;
    color: #8378de;
  }
  
  .p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button, .p-fileupload-choose.p-button-danger {
    color: #ffffff;
    background: #d13438;
    border: 1px solid #d13438;
  }
  .p-button.p-button-danger:enabled:hover, .p-button.p-button-danger:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger:enabled:hover, .p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):hover {
    background: #c02b2f;
    color: #ffffff;
    border-color: #c02b2f;
  }
  .p-button.p-button-danger:enabled:focus, .p-button.p-button-danger:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-danger:enabled:focus, .p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #edaeaf;
  }
  .p-button.p-button-danger:enabled:active, .p-button.p-button-danger:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger:enabled:active, .p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):active {
    background: #aa272a;
    color: #ffffff;
    border-color: #aa272a;
  }
  .p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined, .p-fileupload-choose.p-button-danger.p-button-outlined {
    background-color: transparent;
    color: #d13438;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-outlined:enabled:hover, .p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(209, 52, 56, 0.04);
    color: #d13438;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-outlined:enabled:active, .p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(209, 52, 56, 0.16);
    color: #d13438;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text, .p-fileupload-choose.p-button-danger.p-button-text {
    background-color: transparent;
    color: #d13438;
    border-color: transparent;
  }
  .p-button.p-button-danger.p-button-text:enabled:hover, .p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger.p-button-text:enabled:hover, .p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(209, 52, 56, 0.04);
    border-color: transparent;
    color: #d13438;
  }
  .p-button.p-button-danger.p-button-text:enabled:active, .p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger.p-button-text:enabled:active, .p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(209, 52, 56, 0.16);
    border-color: transparent;
    color: #d13438;
  }
  
  .p-button.p-button-link {
    color: #9A294B;
    background: transparent;
    border: transparent;
  }
  .p-button.p-button-link:enabled:hover, .p-button.p-button-link:not(button):not(a):not(.p-disabled):hover {
    background: transparent;
    color: #005a9e;
    border-color: transparent;
  }
  .p-button.p-button-link:enabled:hover .p-button-label, .p-button.p-button-link:not(button):not(a):not(.p-disabled):hover .p-button-label {
    text-decoration: underline;
  }
  .p-button.p-button-link:enabled:focus, .p-button.p-button-link:not(button):not(a):not(.p-disabled):focus {
    background: transparent;
    box-shadow: 0 0 0 1px #605e5c;
    border-color: transparent;
  }
  .p-button.p-button-link:enabled:active, .p-button.p-button-link:not(button):not(a):not(.p-disabled):active {
    background: transparent;
    color: #9A294B;
    border-color: transparent;
  }
  
  .p-speeddial-button.p-button.p-button-icon-only {
    width: 4rem;
    height: 4rem;
  }
  .p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
    font-size: 1.3rem;
  }
  
  .p-speeddial-action {
    width: 3rem;
    height: 3rem;
    background: #323130;
    color: #ffffff;
  }
  .p-speeddial-action:hover {
    background: #605e5c;
    color: #ffffff;
  }
  
  .p-speeddial-direction-up .p-speeddial-item {
    margin: 0.25rem;
  }
  .p-speeddial-direction-up .p-speeddial-item:first-child {
    margin-bottom: 0.5rem;
  }
  
  .p-speeddial-direction-down .p-speeddial-item {
    margin: 0.25rem;
  }
  .p-speeddial-direction-down .p-speeddial-item:first-child {
    margin-top: 0.5rem;
  }
  
  .p-speeddial-direction-left .p-speeddial-item {
    margin: 0 0.25rem;
  }
  .p-speeddial-direction-left .p-speeddial-item:first-child {
    margin-right: 0.5rem;
  }
  
  .p-speeddial-direction-right .p-speeddial-item {
    margin: 0 0.25rem;
  }
  .p-speeddial-direction-right .p-speeddial-item:first-child {
    margin-left: 0.5rem;
  }
  
  .p-speeddial-circle .p-speeddial-item,
  .p-speeddial-semi-circle .p-speeddial-item,
  .p-speeddial-quarter-circle .p-speeddial-item {
    margin: 0;
  }
  .p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child,
  .p-speeddial-semi-circle .p-speeddial-item:first-child,
  .p-speeddial-semi-circle .p-speeddial-item:last-child,
  .p-speeddial-quarter-circle .p-speeddial-item:first-child,
  .p-speeddial-quarter-circle .p-speeddial-item:last-child {
    margin: 0;
  }
  
  .p-speeddial-mask {
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .p-carousel .p-carousel-content .p-carousel-prev,
  .p-carousel .p-carousel-content .p-carousel-next {
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin: 0.5rem;
  }
  .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
  .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .p-carousel .p-carousel-content .p-carousel-prev:focus,
  .p-carousel .p-carousel-content .p-carousel-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-carousel .p-carousel-indicators {
    padding: 1rem;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #c8c6c4;
    width: 2rem;
    height: 0.5rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: #e1dfdd;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: #edebe9;
    color: #323130;
  }
  
  .p-datatable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-datatable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-datatable .p-datatable-header {
    background: #ffffff;
    color: #323130;
    border: 1px solid #f3f2f1;
    border-width: 1px 0 1px 0;
    padding: 0.75rem 0.75rem;
    font-weight: 600;
  }
  .p-datatable .p-datatable-footer {
    background: #ffffff;
    color: #323130;
    border: 1px solid #f3f2f1;
    border-width: 0 0 1px 0;
    padding: 0.75rem 0.75rem;
    font-weight: 600;
  }
  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 0.75rem 0.75rem;
    border: 1px solid #f3f2f1;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #323130;
    background: #ffffff;
    transition: box-shadow 0.2s;
  }
  .p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 0.75rem 0.75rem;
    border: 1px solid #f3f2f1;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #323130;
    background: #ffffff;
  }
  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #605e5c;
    margin-left: 0.5rem;
  }
  .p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #323130;
    background: #edebe9;
    margin-left: 0.5rem;
  }
  .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: #f3f2f1;
    color: #323130;
  }
  .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #605e5c;
  }
  .p-datatable .p-sortable-column.p-highlight {
    background: #edebe9;
    color: #323130;
  }
  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #323130;
  }
  .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: #f3f2f1;
    color: #323130;
  }
  .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #323130;
  }
  .p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 1px #605e5c;
    outline: 0 none;
  }
  .p-datatable .p-datatable-tbody > tr {
    background: #ffffff;
    color: #323130;
    transition: box-shadow 0.2s;
    outline-color: #605e5c;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #f3f2f1;
    border-width: 0 0 1px 0;
    padding: 0.75rem 0.75rem;
    outline-color: #605e5c;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: 0.5rem;
  }
  .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td.p-highlight {
    background: #edebe9;
    color: #323130;
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #edebe9;
    color: #323130;
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 #edebe9;
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 #edebe9;
  }
  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: #f3f2f1;
    color: #323130;
  }
  .p-datatable .p-column-resizer-helper {
    background: #9A294B;
  }
  .p-datatable .p-datatable-scrollable-header,
  .p-datatable .p-datatable-scrollable-footer {
    background: #faf9f8;
  }
  .p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background: #faf9f8;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
    background: #edebe9;
    color: #323130;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler {
    color: #323130;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
    color: #323130;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd + .p-row-expanded {
    background: #faf9f8;
  }
  .p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.375rem 0.375rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.375rem 0.375rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.375rem 0.375rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: 0.375rem 0.375rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.375rem 0.375rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-header {
    padding: 0.9375rem 0.9375rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 0.9375rem 0.9375rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: 0.9375rem 0.9375rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
    padding: 0.9375rem 0.9375rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 0.9375rem 0.9375rem;
  }
  
  .p-datatable-drag-selection-helper {
    background: rgba(0, 120, 212, 0.16);
  }
  
  .p-dataview .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-dataview .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-dataview .p-dataview-header {
    background: #ffffff;
    color: #323130;
    border: 1px solid #f3f2f1;
    border-width: 1px 0 1px 0;
    padding: 0.75rem 0.75rem;
    font-weight: 600;
  }
  .p-dataview .p-dataview-content {
    background: #ffffff;
    color: #323130;
    border: 0 none;
    padding: 0;
  }
  .p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: solid #f3f2f1;
    border-width: 0 0 1px 0;
  }
  .p-dataview .p-dataview-footer {
    background: #ffffff;
    color: #323130;
    border: 1px solid #f3f2f1;
    border-width: 0 0 1px 0;
    padding: 0.75rem 0.75rem;
    font-weight: 600;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .p-dataview .p-dataview-loading-icon {
    font-size: 2rem;
  }
  
  .p-datascroller .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-datascroller .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-datascroller .p-datascroller-header {
    background: #ffffff;
    color: #323130;
    border: 1px solid #f3f2f1;
    border-width: 1px 0 1px 0;
    padding: 0.75rem 0.75rem;
    font-weight: 600;
  }
  .p-datascroller .p-datascroller-content {
    background: #ffffff;
    color: #323130;
    border: 0 none;
    padding: 0;
  }
  .p-datascroller.p-datascroller-inline .p-datascroller-list > li {
    border: solid #f3f2f1;
    border-width: 0 0 1px 0;
  }
  .p-datascroller .p-datascroller-footer {
    background: #ffffff;
    color: #323130;
    border: 1px solid #f3f2f1;
    border-width: 0 0 1px 0;
    padding: 0.75rem 0.75rem;
    font-weight: 600;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  .p-column-filter-row .p-column-filter-menu-button,
  .p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0.5rem;
  }
  
  .p-column-filter-menu-button {
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-column-filter-menu-button:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: #f3f2f1;
    color: #605e5c;
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: #edebe9;
    color: #323130;
  }
  .p-column-filter-menu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  
  .p-column-filter-clear-button {
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-column-filter-clear-button:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .p-column-filter-clear-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  
  .p-column-filter-overlay {
    background: #ffffff;
    color: #323130;
    border: 0 none;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
    min-width: 12.5rem;
  }
  .p-column-filter-overlay .p-column-filter-row-items {
    padding: 0;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 0;
    padding: 0.75rem 0.5rem;
    border: 1px solid transparent;
    color: #323130;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: #323130;
    background: #edebe9;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: #323130;
    background: #f3f2f1;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid #edebe9;
    margin: 0.25rem 0;
  }
  
  .p-column-filter-overlay-menu .p-column-filter-operator {
    padding: 0.75rem 0.5rem;
    border-bottom: 1px solid #edebe9;
    color: #323130;
    background: #ffffff;
    margin: 0;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 1rem;
    border-bottom: 1px solid #edebe9;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
  }
  .p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0.5rem 1rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 1rem;
  }
  .fc.fc-unthemed .fc-view-container th {
    background: #faf9f8;
    border: 1px solid #a19f9d;
    color: #323130;
  }
  .fc.fc-unthemed .fc-view-container td.fc-widget-content {
    border: 1px solid #a19f9d;
    color: #323130;
  }
  .fc.fc-unthemed .fc-view-container td.fc-head-container {
    border: 1px solid #a19f9d;
  }
  .fc.fc-unthemed .fc-view-container .fc-view {
    background: #ffffff;
  }
  .fc.fc-unthemed .fc-view-container .fc-row {
    border-right: 1px solid #a19f9d;
  }
  .fc.fc-unthemed .fc-view-container .fc-event {
    background: #9A294B;
    border: 1px solid #9A294B;
    color: #ffffff;
  }
  .fc.fc-unthemed .fc-view-container .fc-divider {
    background: #faf9f8;
    border: 1px solid #a19f9d;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button {
    color: #ffffff;
    background: #9A294B;
    border: 1px solid #9A294B;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 2px;
    display: flex;
    align-items: center;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
    background: #106ebe;
    color: #ffffff;
    border-color: #106ebe;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
    background: #005a9e;
    color: #ffffff;
    border-color: #005a9e;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: "\e900";
  }
  .fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: "\e901";
  }
  .fc.fc-unthemed .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: #ffffff;
    border: 1px solid #605e5c;
    color: #323130;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
    background: #f3f2f1;
    border-color: #605e5c;
    color: #323130;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
    background: #edebe9;
    border-color: #605e5c;
    color: #323130;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
    background: #edebe9;
    border-color: #605e5c;
    color: #323130;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
    z-index: 1;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
    border-color: #a19f9d;
  }
  .fc.fc-theme-standard .fc-view-harness th {
    background: #faf9f8;
    border-color: #a19f9d;
    color: #323130;
  }
  .fc.fc-theme-standard .fc-view-harness td {
    color: #323130;
    border-color: #a19f9d;
  }
  .fc.fc-theme-standard .fc-view-harness .fc-view {
    background: #ffffff;
  }
  .fc.fc-theme-standard .fc-view-harness .fc-popover {
    background: none;
    border: 0 none;
  }
  .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
    border: 1px solid #a19f9d;
    padding: 1rem;
    background: #faf9f8;
    color: #323130;
  }
  .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "PrimeIcons" !important;
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
    content: "\e90b";
  }
  .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
    padding: 1rem;
    border: 1px solid #a19f9d;
    background: #ffffff;
    color: #323130;
    border-top: 0 none;
  }
  .fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
    color: #ffffff;
    background: #9A294B;
    border-color: #9A294B;
  }
  .fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
    color: #ffffff;
  }
  .fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
    background: #9A294B;
    border-color: #9A294B;
  }
  .fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
    background: #f3f2f1;
    color: #323130;
  }
  .fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
    background: #faf9f8;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button {
    color: #ffffff;
    background: #9A294B;
    border: 1px solid #9A294B;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 2px;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
    background: #106ebe;
    color: #ffffff;
    border-color: #106ebe;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
    background: #005a9e;
    color: #ffffff;
    border-color: #005a9e;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
    opacity: 0.6;
    color: #ffffff;
    background: #9A294B;
    border: 1px solid #9A294B;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: "\e900";
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: "\e901";
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: #ffffff;
    border: 1px solid #605e5c;
    color: #323130;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
    background: #f3f2f1;
    border-color: #605e5c;
    color: #323130;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
    background: #edebe9;
    border-color: #605e5c;
    color: #323130;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
    background: #edebe9;
    border-color: #605e5c;
    color: #323130;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
    z-index: 1;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .fc.fc-theme-standard .fc-highlight {
    color: #323130;
    background: #edebe9;
  }
  
  .p-orderlist .p-orderlist-controls {
    padding: 1rem;
  }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: 0.5rem;
  }
  .p-orderlist .p-orderlist-header {
    background: #faf9f8;
    color: #323130;
    border: 1px solid #a19f9d;
    padding: 1rem;
    font-weight: 600;
    border-bottom: 0 none;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .p-orderlist .p-orderlist-list {
    border: 1px solid #a19f9d;
    background: #ffffff;
    color: #323130;
    padding: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.75rem 0.5rem;
    margin: 0;
    border: 1px solid transparent;
    color: #323130;
    background: transparent;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
    background: #f3f2f1;
    color: #323130;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: #323130;
    background: #edebe9;
  }
  
  @media screen and (max-width: 769px) {
    .p-orderlist {
      flex-direction: column;
    }
    .p-orderlist .p-orderlist-controls {
      padding: 1rem;
      flex-direction: row;
    }
    .p-orderlist .p-orderlist-controls .p-button {
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
    .p-orderlist .p-orderlist-controls .p-button:last-child {
      margin-right: 0;
    }
  }
  .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    background: #f3f2f1;
    color: #323130;
  }
  .p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: #edebe9;
    color: #323130;
  }
  .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #b4aba3;
  }
  .p-organizationchart .p-organizationchart-line-down {
    background: #a19f9d;
  }
  .p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid #a19f9d;
    border-color: #a19f9d;
  }
  .p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid #a19f9d;
    border-color: #a19f9d;
  }
  .p-organizationchart .p-organizationchart-node-content {
    border: 1px solid #a19f9d;
    background: #ffffff;
    color: #323130;
    padding: 1rem;
  }
  .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;
  }
  .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  
  .p-paginator {
    background: #ffffff;
    color: #605e5c;
    border: solid #f3f2f1;
    border-width: 0;
    padding: 0.5rem 1rem;
    border-radius: 2px;
  }
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: #605e5c;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 2px;
  }
  .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: #f3f2f1;
    border-color: transparent;
    color: #323130;
  }
  .p-paginator .p-paginator-first {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .p-paginator .p-paginator-last {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .p-paginator .p-dropdown {
    margin-left: 0.5rem;
    height: 2.357rem;
  }
  .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
  }
  .p-paginator .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 2.357rem;
  }
  .p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: #605e5c;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
  }
  .p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #605e5c;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 2px;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #edebe9;
    border-color: #edebe9;
    color: #323130;
  }
  .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: #f3f2f1;
    border-color: transparent;
    color: #323130;
  }
  
  .p-picklist .p-picklist-buttons {
    padding: 1rem;
  }
  .p-picklist .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem;
  }
  .p-picklist .p-picklist-header {
    background: #faf9f8;
    color: #323130;
    border: 1px solid #a19f9d;
    padding: 1rem;
    font-weight: 600;
    border-bottom: 0 none;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .p-picklist .p-picklist-list {
    border: 1px solid #a19f9d;
    background: #ffffff;
    color: #323130;
    padding: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.75rem 0.5rem;
    margin: 0;
    border: 1px solid transparent;
    color: #323130;
    background: transparent;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
    background: #f3f2f1;
    color: #323130;
  }
  .p-picklist .p-picklist-list .p-picklist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #323130;
    background: #edebe9;
  }
  
  @media screen and (max-width: 769px) {
    .p-picklist {
      flex-direction: column;
    }
    .p-picklist .p-picklist-buttons {
      padding: 1rem;
      flex-direction: row;
    }
    .p-picklist .p-picklist-buttons .p-button {
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
    .p-picklist .p-picklist-buttons .p-button:last-child {
      margin-right: 0;
    }
    .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
      content: "\e930";
    }
    .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
      content: "\e92c";
    }
    .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
      content: "\e933";
    }
    .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
      content: "\e92f";
    }
  }
  .p-tree {
    border: 1px solid #a19f9d;
    background: #ffffff;
    color: #323130;
    padding: 1rem;
    border-radius: 2px;
  }
  .p-tree .p-tree-container .p-treenode {
    padding: 0.143rem;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 2px;
    transition: box-shadow 0.2s;
    padding: 0.5rem;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    margin-right: 0.5rem;
    color: #605e5c;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #323130;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: #edebe9;
    color: #323130;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: #323130;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: #323130;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #f3f2f1;
    color: #323130;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
    background: #f3f2f1;
    color: #323130;
  }
  .p-tree .p-tree-filter-container {
    margin-bottom: 0.5rem;
  }
  .p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.5rem;
  }
  .p-tree .p-tree-filter-container .p-tree-filter-icon {
    right: 0.5rem;
    color: #605e5c;
  }
  .p-tree .p-treenode-children {
    padding: 0 0 0 1rem;
  }
  .p-tree .p-tree-loading-icon {
    font-size: 2rem;
  }
  .p-tree .p-treenode-droppoint {
    height: 0.5rem;
  }
  .p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    background: #c3bcb5;
  }
  
  .p-treetable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-treetable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-treetable .p-treetable-header {
    background: #ffffff;
    color: #323130;
    border: 1px solid #f3f2f1;
    border-width: 1px 0 1px 0;
    padding: 0.75rem 0.75rem;
    font-weight: 600;
  }
  .p-treetable .p-treetable-footer {
    background: #ffffff;
    color: #323130;
    border: 1px solid #f3f2f1;
    border-width: 0 0 1px 0;
    padding: 0.75rem 0.75rem;
    font-weight: 600;
  }
  .p-treetable .p-treetable-thead > tr > th {
    text-align: left;
    padding: 0.75rem 0.75rem;
    border: 1px solid #f3f2f1;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #323130;
    background: #ffffff;
    transition: box-shadow 0.2s;
  }
  .p-treetable .p-treetable-tfoot > tr > td {
    text-align: left;
    padding: 0.75rem 0.75rem;
    border: 1px solid #f3f2f1;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #323130;
    background: #ffffff;
  }
  .p-treetable .p-sortable-column {
    outline-color: #605e5c;
  }
  .p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #605e5c;
    margin-left: 0.5rem;
  }
  .p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #323130;
    background: #edebe9;
    margin-left: 0.5rem;
  }
  .p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: #f3f2f1;
    color: #323130;
  }
  .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #605e5c;
  }
  .p-treetable .p-sortable-column.p-highlight {
    background: #edebe9;
    color: #323130;
  }
  .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #323130;
  }
  .p-treetable .p-treetable-tbody > tr {
    background: #ffffff;
    color: #323130;
    transition: box-shadow 0.2s;
    outline-color: #605e5c;
  }
  .p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 1px solid #f3f2f1;
    border-width: 0 0 1px 0;
    padding: 0.75rem 0.75rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #323130;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight {
    background: #edebe9;
    color: #323130;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
    color: #323130;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
    color: #323130;
  }
  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
    background: #f3f2f1;
    color: #323130;
  }
  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
    color: #323130;
  }
  .p-treetable .p-column-resizer-helper {
    background: #9A294B;
  }
  .p-treetable .p-treetable-scrollable-header,
  .p-treetable .p-treetable-scrollable-footer {
    background: #faf9f8;
  }
  .p-treetable .p-treetable-loading-icon {
    font-size: 2rem;
  }
  .p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px 0 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
    border-width: 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
    border-width: 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
    border-width: 1px;
  }
  .p-treetable.p-treetable-sm .p-treetable-header {
    padding: 0.65625rem 0.65625rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
    padding: 0.375rem 0.375rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
    padding: 0.375rem 0.375rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
    padding: 0.375rem 0.375rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-footer {
    padding: 0.375rem 0.375rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-header {
    padding: 0.9375rem 0.9375rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
    padding: 0.9375rem 0.9375rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
    padding: 0.9375rem 0.9375rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
    padding: 0.9375rem 0.9375rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-footer {
    padding: 0.9375rem 0.9375rem;
  }
  
  .p-timeline .p-timeline-event-marker {
    border: 0 none;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #9A294B;
  }
  .p-timeline .p-timeline-event-connector {
    background-color: #a19f9d;
  }
  .p-timeline.p-timeline-vertical .p-timeline-event-opposite,
  .p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem;
  }
  .p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px;
  }
  .p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
  .p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0;
  }
  .p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px;
  }
  
  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem;
    border: 1px solid #a19f9d;
    color: #323130;
    background: #faf9f8;
    font-weight: 600;
    border-radius: 2px;
    transition: box-shadow 0.2s;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: 0.5rem;
  }
  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: #f3f2f1;
    border-color: #a19f9d;
    color: #323130;
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #faf9f8;
    border-color: #a19f9d;
    color: #323130;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-color: #a19f9d;
    background: #f3f2f1;
    color: #323130;
  }
  .p-accordion .p-accordion-content {
    padding: 1rem;
    border: 1px solid #a19f9d;
    background: #ffffff;
    color: #323130;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .p-accordion .p-accordion-tab {
    margin-bottom: 4px;
  }
  
  .p-card {
    background: #ffffff;
    color: #323130;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
    border-radius: 2px;
  }
  .p-card .p-card-body {
    padding: 1rem;
  }
  .p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .p-card .p-card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: #605e5c;
  }
  .p-card .p-card-content {
    padding: 1rem 0;
  }
  .p-card .p-card-footer {
    padding: 1rem 0 0 0;
  }
  
  .p-fieldset {
    border: 1px solid #a19f9d;
    background: #ffffff;
    color: #323130;
    border-radius: 2px;
  }
  .p-fieldset .p-fieldset-legend {
    padding: 1rem;
    border: 1px solid #a19f9d;
    color: #323130;
    background: #faf9f8;
    font-weight: 600;
    border-radius: 2px;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 1rem;
    color: #323130;
    border-radius: 2px;
    transition: box-shadow 0.2s;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: 0.5rem;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: #f3f2f1;
    border-color: #a19f9d;
    color: #323130;
  }
  .p-fieldset .p-fieldset-content {
    padding: 1rem;
  }
  
  .p-divider .p-divider-content {
    background-color: #ffffff;
  }
  .p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem;
  }
  .p-divider.p-divider-horizontal:before {
    border-top: 1px #edebe9;
  }
  .p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 0.5rem;
  }
  .p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0;
  }
  .p-divider.p-divider-vertical:before {
    border-left: 1px #edebe9;
  }
  .p-divider.p-divider-vertical .p-divider-content {
    padding: 0.5rem 0;
  }
  
  .p-panel .p-panel-header {
    border: 1px solid #a19f9d;
    padding: 1rem;
    background: #faf9f8;
    color: #323130;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .p-panel .p-panel-header .p-panel-title {
    font-weight: 600;
  }
  .p-panel .p-panel-header .p-panel-header-icon {
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .p-panel .p-panel-header .p-panel-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-panel.p-panel-toggleable .p-panel-header {
    padding: 0.5rem 1rem;
  }
  .p-panel .p-panel-content {
    padding: 1rem;
    border: 1px solid #a19f9d;
    background: #ffffff;
    color: #323130;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top: 0 none;
  }
  .p-panel .p-panel-footer {
    padding: 0.5rem 1rem;
    border: 1px solid #a19f9d;
    background: #ffffff;
    color: #323130;
    border-top: 0 none;
  }
  
  .p-splitter {
    border: 1px solid #a19f9d;
    background: #ffffff;
    border-radius: 2px;
    color: #323130;
  }
  .p-splitter .p-splitter-gutter {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    background: #faf9f8;
  }
  .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: #edebe9;
  }
  .p-splitter .p-splitter-gutter-resizing {
    background: #edebe9;
  }
  
  .p-scrollpanel .p-scrollpanel-bar {
    background: #f3f2f1;
    border: 0 none;
  }
  
  .p-tabview .p-tabview-nav {
    background: #ffffff;
    border: 0 none;
    border-width: 0 0 2px 0;
  }
  .p-tabview .p-tabview-nav li {
    margin-right: 0;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #a19f9d;
    border-width: 0 0 2px 0;
    border-color: transparent transparent transparent transparent;
    background: #ffffff;
    color: #605e5c;
    padding: 1rem;
    font-weight: 600;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    transition: box-shadow 0.2s;
    margin: 0 0.5rem -2px 0;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset inset 0 0 0 1px #605e5c;
  }
  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: #f3f2f1;
    border-color: transparent;
    color: #605e5c;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: #9A294B;
    color: #323130;
  }
  .p-tabview .p-tabview-close {
    margin-left: 0.5rem;
  }
  .p-tabview .p-tabview-nav-btn.p-link {
    background: #ffffff;
    color: #323130;
    width: 2.357rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
  }
  .p-tabview .p-tabview-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset inset 0 0 0 1px #605e5c;
  }
  .p-tabview .p-tabview-panels {
    background: #ffffff;
    padding: 1rem;
    border: 0 none;
    color: #323130;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  
  .p-toolbar {
    background: #faf9f8;
    border: 1px solid #a19f9d;
    padding: 1rem;
    border-radius: 2px;
  }
  .p-toolbar .p-toolbar-separator {
    margin: 0 0.5rem;
  }
  
  .p-confirm-popup {
    background: #ffffff;
    color: #323130;
    border: 0 none;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
  }
  .p-confirm-popup .p-confirm-popup-content {
    padding: 1rem;
  }
  .p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: 0 1rem 1rem 1rem;
  }
  .p-confirm-popup .p-confirm-popup-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
  }
  .p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0;
  }
  .p-confirm-popup:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }
  .p-confirm-popup:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }
  .p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: #ffffff;
  }
  .p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: #ffffff;
  }
  .p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem;
  }
  .p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem;
  }
  
  .p-dialog {
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
    border: 0 none;
  }
  .p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #323130;
    padding: 1.5rem;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 1.5rem;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
  }
  .p-dialog .p-dialog-content {
    background: #ffffff;
    color: #323130;
    padding: 0 1.5rem 2rem 1.5rem;
  }
  .p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #ffffff;
    color: #323130;
    padding: 0 1.5rem 1.5rem 1.5rem;
    text-align: right;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
  }
  
  .p-overlaypanel {
    background: #ffffff;
    color: #323130;
    border: 0 none;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
  }
  .p-overlaypanel .p-overlaypanel-content {
    padding: 1rem;
  }
  .p-overlaypanel .p-overlaypanel-close {
    background: #9A294B;
    color: #ffffff;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 2px;
    position: absolute;
    top: -1rem;
    right: -1rem;
  }
  .p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #106ebe;
    color: #ffffff;
  }
  .p-overlaypanel:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }
  .p-overlaypanel:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }
  .p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #ffffff;
  }
  .p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #ffffff;
  }
  
  .p-sidebar {
    background: #ffffff;
    color: #323130;
    border: 0 none;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
  }
  .p-sidebar .p-sidebar-header {
    padding: 1rem;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close,
  .p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 2rem;
    height: 2rem;
    color: #605e5c;
    border: 0 none;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: #605e5c;
    border-color: transparent;
    background: #f3f2f1;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:focus,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding-top: 0;
  }
  .p-sidebar .p-sidebar-content {
    padding: 1rem;
  }
  
  .p-tooltip .p-tooltip-text {
    background: #ffffff;
    color: #323130;
    padding: 0.5rem 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
    border-radius: 2px;
  }
  .p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #ffffff;
  }
  .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #ffffff;
  }
  .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #ffffff;
  }
  .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #ffffff;
  }
  
  .p-fileupload .p-fileupload-buttonbar {
    background: #faf9f8;
    padding: 1rem;
    border: 1px solid #a19f9d;
    color: #323130;
    border-bottom: 0 none;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 0.5rem;
  }
  .p-fileupload .p-fileupload-content {
    background: #ffffff;
    padding: 2rem 1rem;
    border: 1px solid #a19f9d;
    color: #323130;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .p-fileupload .p-progressbar {
    height: 2px;
  }
  .p-fileupload .p-fileupload-row > div {
    padding: 0.75rem 0.75rem;
  }
  .p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0;
  }
  
  .p-breadcrumb {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    padding: 1rem;
  }
  .p-breadcrumb ul li .p-menuitem-link {
    transition: box-shadow 0.2s;
    border-radius: 2px;
  }
  .p-breadcrumb ul li .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
    color: #9A294B;
  }
  .p-breadcrumb ul li.p-breadcrumb-chevron {
    margin: 0 0.5rem 0 0.5rem;
    color: #323130;
  }
  .p-breadcrumb ul li:last-child .p-menuitem-text {
    color: #323130;
  }
  .p-breadcrumb ul li:last-child .p-menuitem-icon {
    color: #9A294B;
  }
  
  .p-contextmenu {
    padding: 0;
    background: #ffffff;
    color: #323130;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    width: 12.5rem;
  }
  .p-contextmenu .p-menuitem-link {
    padding: 0.75rem 0.5rem;
    color: #323130;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-contextmenu .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-contextmenu .p-menuitem-link .p-menuitem-icon {
    color: #9A294B;
    margin-right: 0.5rem;
  }
  .p-contextmenu .p-menuitem-link .p-submenu-icon {
    color: #9A294B;
  }
  .p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #f3f2f1;
  }
  .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #323130;
  }
  .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #9A294B;
  }
  .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #9A294B;
  }
  .p-contextmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-contextmenu .p-submenu-list {
    padding: 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 2px;
  }
  .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #edebe9;
  }
  .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #9A294B;
  }
  .p-contextmenu .p-menu-separator {
    border-top: 1px solid #edebe9;
    margin: 0.25rem 0;
  }
  .p-contextmenu .p-submenu-icon {
    font-size: 0.875rem;
  }
  
  .p-dock .p-dock-list {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
  }
  .p-dock .p-dock-item {
    padding: 0.5rem;
  }
  .p-dock .p-dock-action {
    width: 4rem;
    height: 4rem;
  }
  .p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-prev,
  .p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-prev,
  .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-next {
    margin: 0 0.9rem;
  }
  .p-dock.p-dock-magnification.p-dock-top .p-dock-item-prev,
  .p-dock.p-dock-magnification.p-dock-top .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-prev,
  .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-next {
    margin: 0 1.3rem;
  }
  .p-dock.p-dock-magnification.p-dock-top .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-current {
    margin: 0 1.5rem;
  }
  .p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-prev,
  .p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-prev,
  .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-next {
    margin: 0.9rem 0;
  }
  .p-dock.p-dock-magnification.p-dock-left .p-dock-item-prev,
  .p-dock.p-dock-magnification.p-dock-left .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-prev,
  .p-dock.p-dock-magnification.p-dock-right .p-dock-item-next {
    margin: 1.3rem 0;
  }
  .p-dock.p-dock-magnification.p-dock-left .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-current {
    margin: 1.5rem 0;
  }
  
  @media screen and (max-width: 960px) {
    .p-dock.p-dock-top .p-dock-list-container, .p-dock.p-dock-bottom .p-dock-list-container {
      overflow-x: auto;
      width: 100%;
    }
    .p-dock.p-dock-top .p-dock-list-container .p-dock-list, .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
      margin: 0 auto;
    }
    .p-dock.p-dock-left .p-dock-list-container, .p-dock.p-dock-right .p-dock-list-container {
      overflow-y: auto;
      height: 100%;
    }
    .p-dock.p-dock-left .p-dock-list-container .p-dock-list, .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
      margin: auto 0;
    }
    .p-dock .p-dock-list .p-dock-item {
      transform: none;
      margin: 0;
    }
  }
  .p-megamenu {
    padding: 0.5rem;
    background: #ffffff;
    color: #323130;
    border: 1px solid #eeeeee;
    border-radius: 2px;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 0.5rem;
    color: #323130;
    border-radius: 2px;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #9A294B;
    margin-right: 0.5rem;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #9A294B;
    margin-left: 0.5rem;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #f3f2f1;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #323130;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #9A294B;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #9A294B;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: #edebe9;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #323130;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #9A294B;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #9A294B;
  }
  .p-megamenu .p-menuitem-link {
    padding: 0.75rem 0.5rem;
    color: #323130;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-megamenu .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-megamenu .p-menuitem-link .p-menuitem-icon {
    color: #9A294B;
    margin-right: 0.5rem;
  }
  .p-megamenu .p-menuitem-link .p-submenu-icon {
    color: #9A294B;
  }
  .p-megamenu .p-menuitem-link:not(.p-disabled):hover {
    background: #f3f2f1;
  }
  .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #323130;
  }
  .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #9A294B;
  }
  .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #9A294B;
  }
  .p-megamenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-megamenu .p-megamenu-panel {
    background: #ffffff;
    color: #323130;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .p-megamenu .p-megamenu-submenu-header {
    margin: 0;
    padding: 0.75rem 0.5rem;
    color: #9A294B;
    background: #ffffff;
    font-weight: 600;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .p-megamenu .p-megamenu-submenu {
    padding: 0;
    width: 12.5rem;
  }
  .p-megamenu .p-megamenu-submenu .p-menu-separator {
    border-top: 1px solid #edebe9;
    margin: 0.25rem 0;
  }
  .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #edebe9;
  }
  .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #9A294B;
  }
  .p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: 0;
  }
  
  .p-menu {
    padding: 0;
    background: #ffffff;
    color: #323130;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    width: 12.5rem;
  }
  .p-menu .p-menuitem-link {
    padding: 0.75rem 0.5rem;
    color: #323130;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-menu .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-menu .p-menuitem-link .p-menuitem-icon {
    color: #9A294B;
    margin-right: 0.5rem;
  }
  .p-menu .p-menuitem-link .p-submenu-icon {
    color: #9A294B;
  }
  .p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: #f3f2f1;
  }
  .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #323130;
  }
  .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #9A294B;
  }
  .p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #9A294B;
  }
  .p-menu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-menu.p-menu-overlay {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .p-menu .p-submenu-header {
    margin: 0;
    padding: 0.75rem 0.5rem;
    color: #9A294B;
    background: #ffffff;
    font-weight: 600;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .p-menu .p-menu-separator {
    border-top: 1px solid #edebe9;
    margin: 0.25rem 0;
  }
  
  .p-menubar {
    padding: 0.5rem;
    background: #ffffff;
    color: #323130;
    border: 1px solid #eeeeee;
    border-radius: 2px;
  }
  .p-menubar .p-menuitem-link {
    padding: 0.75rem 0.5rem;
    color: #323130;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-menubar .p-menuitem-link .p-menuitem-icon {
    color: #9A294B;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menuitem-link .p-submenu-icon {
    color: #9A294B;
  }
  .p-menubar .p-menuitem-link:not(.p-disabled):hover {
    background: #f3f2f1;
  }
  .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #323130;
  }
  .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #9A294B;
  }
  .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #9A294B;
  }
  .p-menubar .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 0.5rem;
    color: #323130;
    border-radius: 2px;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #9A294B;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #9A294B;
    margin-left: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #f3f2f1;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #323130;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #9A294B;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #9A294B;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: #edebe9;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #323130;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #9A294B;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #9A294B;
  }
  .p-menubar .p-submenu-list {
    padding: 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 12.5rem;
  }
  .p-menubar .p-submenu-list .p-menu-separator {
    border-top: 1px solid #edebe9;
    margin: 0.25rem 0;
  }
  .p-menubar .p-submenu-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #edebe9;
  }
  .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #9A294B;
  }
  
  @media screen and (max-width: 960px) {
    .p-menubar {
      position: relative;
    }
    .p-menubar .p-menubar-button {
      display: flex;
      width: 2rem;
      height: 2rem;
      color: #9A294B;
      border-radius: 2px;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }
    .p-menubar .p-menubar-button:hover {
      color: #9A294B;
      background: #f3f2f1;
    }
    .p-menubar .p-menubar-button:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 1px #605e5c;
    }
    .p-menubar .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: 0;
      background: #ffffff;
      border: 0 none;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      width: 100%;
    }
    .p-menubar .p-menubar-root-list .p-menu-separator {
      border-top: 1px solid #edebe9;
      margin: 0.25rem 0;
    }
    .p-menubar .p-menubar-root-list .p-submenu-icon {
      font-size: 0.875rem;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem {
      width: 100%;
      position: static;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
      padding: 0.75rem 0.5rem;
      color: #323130;
      border-radius: 0;
      transition: box-shadow 0.2s;
      user-select: none;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
      color: #323130;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
      color: #9A294B;
      margin-right: 0.5rem;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
      color: #9A294B;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
      background: #f3f2f1;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
      color: #323130;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
      color: #9A294B;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
      color: #9A294B;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 1px #605e5c;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
      margin-left: auto;
      transition: transform 0.2s;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
      transform: rotate(-180deg);
    }
    .p-menubar .p-menubar-root-list .p-submenu-list {
      width: 100%;
      position: static;
      box-shadow: none;
      border: 0 none;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
      transition: transform 0.2s;
      transform: rotate(90deg);
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
      transform: rotate(-90deg);
    }
    .p-menubar .p-menubar-root-list .p-menuitem {
      width: 100%;
      position: static;
    }
    .p-menubar .p-menubar-root-list ul li a {
      padding-left: 2.25rem;
    }
    .p-menubar .p-menubar-root-list ul li ul li a {
      padding-left: 3.75rem;
    }
    .p-menubar .p-menubar-root-list ul li ul li ul li a {
      padding-left: 5.25rem;
    }
    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
      padding-left: 6.75rem;
    }
    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
      padding-left: 8.25rem;
    }
    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      z-index: 1;
    }
  }
  .p-panelmenu .p-panelmenu-header > a {
    padding: 1rem;
    border: 1px solid #a19f9d;
    color: #323130;
    background: #faf9f8;
    font-weight: 600;
    border-radius: 2px;
    transition: box-shadow 0.2s;
  }
  .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-header > a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
    background: #f3f2f1;
    border-color: #a19f9d;
    color: #323130;
  }
  .p-panelmenu .p-panelmenu-header.p-highlight {
    margin-bottom: 0;
  }
  .p-panelmenu .p-panelmenu-header.p-highlight > a {
    background: #faf9f8;
    border-color: #a19f9d;
    color: #323130;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
    border-color: #a19f9d;
    background: #f3f2f1;
    color: #323130;
  }
  .p-panelmenu .p-panelmenu-content {
    padding: 0;
    border: 1px solid #a19f9d;
    background: #ffffff;
    color: #323130;
    margin-bottom: 4px;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.75rem 0.5rem;
    color: #323130;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
    color: #9A294B;
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
    color: #9A294B;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: #f3f2f1;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #323130;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #9A294B;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #9A294B;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0 0 0 1rem;
  }
  .p-panelmenu .p-panelmenu-panel {
    margin-bottom: 4px;
  }
  
  .p-slidemenu {
    padding: 0;
    background: #ffffff;
    color: #323130;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    width: 12.5rem;
  }
  .p-slidemenu .p-menuitem-link {
    padding: 0.75rem 0.5rem;
    color: #323130;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-slidemenu .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-slidemenu .p-menuitem-link .p-menuitem-icon {
    color: #9A294B;
    margin-right: 0.5rem;
  }
  .p-slidemenu .p-menuitem-link .p-submenu-icon {
    color: #9A294B;
  }
  .p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
    background: #f3f2f1;
  }
  .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #323130;
  }
  .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #9A294B;
  }
  .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #9A294B;
  }
  .p-slidemenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-slidemenu.p-slidemenu-overlay {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .p-slidemenu .p-slidemenu-list {
    padding: 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
    background: #edebe9;
  }
  .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
    color: #323130;
  }
  .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
    color: #9A294B;
  }
  .p-slidemenu .p-slidemenu-separator {
    border-top: 1px solid #edebe9;
    margin: 0.25rem 0;
  }
  .p-slidemenu .p-slidemenu-icon {
    font-size: 0.875rem;
  }
  .p-slidemenu .p-slidemenu-backward {
    padding: 0.75rem 0.5rem;
    color: #323130;
  }
  
  .p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 2px;
    background: #ffffff;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #323130;
    border: 1px solid #f3f2f1;
    background: #ffffff;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 50%;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: #605e5c;
  }
  .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #edebe9;
    color: #323130;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 600;
    color: #323130;
  }
  .p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid #edebe9;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
  }
  
  .p-tabmenu .p-tabmenu-nav {
    background: #ffffff;
    border: 0 none;
    border-width: 0 0 2px 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: solid #a19f9d;
    border-width: 0 0 2px 0;
    border-color: transparent transparent transparent transparent;
    background: #ffffff;
    color: #605e5c;
    padding: 1rem;
    font-weight: 600;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    transition: box-shadow 0.2s;
    margin: 0 0.5rem -2px 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset inset 0 0 0 1px #605e5c;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: #f3f2f1;
    border-color: transparent;
    color: #605e5c;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #ffffff;
    border-color: #9A294B;
    color: #323130;
  }
  
  .p-tieredmenu {
    padding: 0;
    background: #ffffff;
    color: #323130;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    width: 12.5rem;
  }
  .p-tieredmenu .p-menuitem-link {
    padding: 0.75rem 0.5rem;
    color: #323130;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    color: #9A294B;
    margin-right: 0.5rem;
  }
  .p-tieredmenu .p-menuitem-link .p-submenu-icon {
    color: #9A294B;
  }
  .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #f3f2f1;
  }
  .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #323130;
  }
  .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #9A294B;
  }
  .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #9A294B;
  }
  .p-tieredmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-tieredmenu.p-tieredmenu-overlay {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .p-tieredmenu .p-submenu-list {
    padding: 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #edebe9;
  }
  .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #323130;
  }
  .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #9A294B;
  }
  .p-tieredmenu .p-menu-separator {
    border-top: 1px solid #edebe9;
    margin: 0.25rem 0;
  }
  .p-tieredmenu .p-submenu-icon {
    font-size: 0.875rem;
  }
  
  .p-inline-message {
    padding: 0.5rem 0.5rem;
    margin: 0;
    border-radius: 2px;
  }
  .p-inline-message.p-inline-message-info {
    background: #f3f2f1;
    border: 0 none;
    border-width: 0px;
    color: #323130;
  }
  .p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #605e5c;
  }
  .p-inline-message.p-inline-message-success {
    background: #dff6dd;
    border: 0 none;
    border-width: 0px;
    color: #323130;
  }
  .p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #107c10;
  }
  .p-inline-message.p-inline-message-warn {
    background: #fff4ce;
    border: 0 none;
    border-width: 0px;
    color: #323130;
  }
  .p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #797775;
  }
  .p-inline-message.p-inline-message-error {
    background: #fde7e9;
    border: 0 none;
    border-width: 0px;
    color: #323130;
  }
  .p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #a80000;
  }
  .p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
  .p-inline-message .p-inline-message-text {
    font-size: 90%;
  }
  .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
  }
  
  .p-message {
    margin: 1rem 0;
    border-radius: 2px;
  }
  .p-message .p-message-wrapper {
    padding: 0.5rem;
  }
  .p-message .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 2px;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-message .p-message-close:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  .p-message .p-message-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-message.p-message-info {
    background: #f3f2f1;
    border: 0 none;
    border-width: 0 0 0 6px;
    color: #323130;
  }
  .p-message.p-message-info .p-message-icon {
    color: #605e5c;
  }
  .p-message.p-message-info .p-message-close {
    color: #605e5c;
  }
  .p-message.p-message-success {
    background: #dff6dd;
    border: 0 none;
    border-width: 0 0 0 6px;
    color: #323130;
  }
  .p-message.p-message-success .p-message-icon {
    color: #107c10;
  }
  .p-message.p-message-success .p-message-close {
    color: #107c10;
  }
  .p-message.p-message-warn {
    background: #fff4ce;
    border: 0 none;
    border-width: 0 0 0 6px;
    color: #323130;
  }
  .p-message.p-message-warn .p-message-icon {
    color: #797775;
  }
  .p-message.p-message-warn .p-message-close {
    color: #797775;
  }
  .p-message.p-message-error {
    background: #fde7e9;
    border: 0 none;
    border-width: 0 0 0 6px;
    color: #323130;
  }
  .p-message.p-message-error .p-message-icon {
    color: #a80000;
  }
  .p-message.p-message-error .p-message-close {
    color: #a80000;
  }
  .p-message .p-message-text {
    font-size: 90%;
    font-weight: 500;
  }
  .p-message .p-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
  
  .p-toast {
    opacity: 0.9;
  }
  .p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 2px;
  }
  .p-toast .p-toast-message .p-toast-message-content {
    padding: 0.5rem;
    border-width: 0;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 1rem;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 1.25rem;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 600;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: 0.5rem 0 0 0;
  }
  .p-toast .p-toast-message .p-toast-icon-close {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 2px;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-toast .p-toast-message .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  .p-toast .p-toast-message .p-toast-icon-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  .p-toast .p-toast-message.p-toast-message-info {
    background: #f3f2f1;
    border: 0 none;
    border-width: 0 0 0 6px;
    color: #323130;
  }
  .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: #605e5c;
  }
  .p-toast .p-toast-message.p-toast-message-success {
    background: #dff6dd;
    border: 0 none;
    border-width: 0 0 0 6px;
    color: #323130;
  }
  .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #107c10;
  }
  .p-toast .p-toast-message.p-toast-message-warn {
    background: #fff4ce;
    border: 0 none;
    border-width: 0 0 0 6px;
    color: #323130;
  }
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: #797775;
  }
  .p-toast .p-toast-message.p-toast-message-error {
    background: #fde7e9;
    border: 0 none;
    border-width: 0 0 0 6px;
    color: #323130;
  }
  .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #a80000;
  }
  
  .p-galleria .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: #faf9f8;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem;
  }
  .p-galleria .p-galleria-close:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #faf9f8;
  }
  .p-galleria .p-galleria-item-nav {
    background: transparent;
    color: #faf9f8;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 2px;
    margin: 0 0.5rem;
  }
  .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
  .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem;
  }
  .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.1);
    color: #faf9f8;
  }
  .p-galleria .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: #faf9f8;
    padding: 1rem;
  }
  .p-galleria .p-galleria-indicators {
    padding: 1rem;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #c8c6c4;
    width: 1rem;
    height: 1rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: #e1dfdd;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #edebe9;
    color: #323130;
  }
  .p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: 0.5rem;
  }
  .p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: 0.5rem;
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: rgba(255, 255, 255, 0.4);
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
    background: rgba(255, 255, 255, 0.6);
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #edebe9;
    color: #323130;
  }
  .p-galleria .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0.25rem;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    margin: 0.5rem;
    background-color: transparent;
    color: #faf9f8;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #faf9f8;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
    transition: box-shadow 0.2s;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  
  .p-galleria-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
  }
  
  .p-image-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
  }
  
  .p-image-preview-indicator {
    background-color: transparent;
    color: #f8f9fa;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  
  .p-image-preview-container:hover > .p-image-preview-indicator {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .p-image-toolbar {
    padding: 1rem;
  }
  
  .p-image-action.p-link {
    color: #f8f9fa;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
  }
  .p-image-action.p-link:last-child {
    margin-right: 0;
  }
  .p-image-action.p-link:hover {
    color: #f8f9fa;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .p-image-action.p-link i {
    font-size: 1.5rem;
  }
  
  .p-avatar {
    background-color: #edebe9;
    border-radius: 2px;
  }
  .p-avatar.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  }
  .p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem;
  }
  .p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }
  .p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem;
  }
  
  .p-avatar-group .p-avatar {
    border: 2px solid #ffffff;
  }
  
  .p-chip {
    background-color: #edebe9;
    color: #323130;
    border-radius: 16px;
    padding: 0 0.5rem;
  }
  .p-chip .p-chip-text {
    line-height: 1.5;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .p-chip .p-chip-icon {
    margin-right: 0.5rem;
  }
  .p-chip img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
  }
  .p-chip .p-chip-remove-icon {
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-left: 0.5rem;
  }
  .p-chip .p-chip-remove-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  
  .p-scrolltop {
    width: 3rem;
    height: 3rem;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-scrolltop.p-link {
    background: rgba(0, 0, 0, 0.7);
  }
  .p-scrolltop.p-link:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  .p-scrolltop .p-scrolltop-icon {
    font-size: 1.5rem;
    color: #faf9f8;
  }
  
  .p-skeleton {
    background-color: #edebe9;
    border-radius: 2px;
  }
  .p-skeleton:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
  }
  
  .p-tag {
    background: #9A294B;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 2px;
  }
  .p-tag.p-tag-success {
    background-color: #498205;
    color: #ffffff;
  }
  .p-tag.p-tag-info {
    background-color: #00b7c3;
    color: #ffffff;
  }
  .p-tag.p-tag-warning {
    background-color: #ffaa44;
    color: #323130;
  }
  .p-tag.p-tag-danger {
    background-color: #d13438;
    color: #ffffff;
  }
  .p-tag .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
  }
  
  .p-inplace .p-inplace-display {
    padding: 0.5rem 0.5rem;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: #f3f2f1;
    color: #323130;
  }
  .p-inplace .p-inplace-display:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #605e5c;
  }
  
  .p-progressbar {
    border: 0 none;
    height: 2px;
    background: #edebe9;
    border-radius: 2px;
  }
  .p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #9A294B;
  }
  .p-progressbar .p-progressbar-label {
    color: #323130;
    line-height: 2px;
  }
  
  .p-terminal {
    background: #ffffff;
    color: #323130;
    border: 1px solid #a19f9d;
    padding: 1rem;
  }
  .p-terminal .p-terminal-input {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  
  .p-badge {
    background: #9A294B;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
  .p-badge.p-badge-secondary {
    background-color: #d45c00;
    color: #ffffff;
  }
  .p-badge.p-badge-success {
    background-color: #498205;
    color: #ffffff;
  }
  .p-badge.p-badge-info {
    background-color: #00b7c3;
    color: #ffffff;
  }
  .p-badge.p-badge-warning {
    background-color: #ffaa44;
    color: #323130;
  }
  .p-badge.p-badge-danger {
    background-color: #d13438;
    color: #ffffff;
  }
  .p-badge.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
  }
  .p-badge.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
  }
  
  .p-tag {
    background: #9A294B;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 2px;
  }
  .p-tag.p-tag-success {
    background-color: #498205;
    color: #ffffff;
  }
  .p-tag.p-tag-info {
    background-color: #00b7c3;
    color: #ffffff;
  }
  .p-tag.p-tag-warning {
    background-color: #ffaa44;
    color: #323130;
  }
  .p-tag.p-tag-danger {
    background-color: #d13438;
    color: #ffffff;
  }
  
  .p-virtualscroller-loading-icon {
    font-size: 2rem;
  }
  
  /* Customizations to the designer theme should be defined here */
  .p-button-label {
    font-weight: 600;
  }
  
  .p-slider:not(.p-disabled):hover {
    background-color: #deecf9;
  }
  .p-slider:not(.p-disabled):hover .p-slider-range {
    background-color: #9A294B;
  }
  .p-slider:not(.p-disabled):hover .p-slider-handle {
    border-color: #005a9e;
  }
  
  .p-inputswitch {
    width: 40px;
    height: 20px;
  }
  .p-inputswitch .p-inputswitch-slider {
    border: 1px solid #605e5c;
  }
  .p-inputswitch .p-inputswitch-slider:before {
    width: 12px;
    height: 12px;
    margin-top: -6px;
  }
  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    border-color: #323130;
  }
  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider:before {
    background-color: #323130;
  }
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    border-color: #9A294B;
  }
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider:before {
    background-color: #ffffff;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    border-color: #9A294B;
  }
  
  .p-datepicker .p-datepicker-header .p-datepicker-title {
    order: 1;
    margin: 0 auto 0 0;
  }
  .p-datepicker .p-datepicker-prev {
    order: 2;
  }
  .p-datepicker .p-datepicker-prev .p-datepicker-prev-icon:before {
    content: "\e919";
  }
  .p-datepicker .p-datepicker-next {
    order: 3;
    font-size: 90%;
  }
  .p-datepicker .p-datepicker-next .p-datepicker-next-icon:before {
    content: "\e91c";
  }
  .p-datepicker .p-datepicker-buttonbar {
    border-top: 0 none;
  }
  .p-datepicker .p-timepicker {
    border-top: 0 none;
  }
  .p-datepicker .p-timepicker span {
    font-size: 90%;
  }
  .p-datepicker .p-timepicker .pi-chevron-up:before {
    content: "\e91c";
  }
  .p-datepicker .p-timepicker .pi-chevron-down:before {
    content: "\e919";
  }
  .p-datepicker table {
    font-size: 90%;
  }
  .p-datepicker table td.p-datepicker-today > span {
    border-radius: 50%;
  }
  .p-datepicker .p-datepicker-buttonbar .p-button {
    font-size: 90%;
    color: #323130;
  }
  .p-datepicker .p-datepicker-buttonbar .p-button .p-button-label {
    font-weight: 400;
  }
  .p-datepicker .p-datepicker-buttonbar .p-button:enabled:hover {
    color: #323130;
    background-color: #f3f2f1;
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.5rem 0;
  }
  
  .p-datatable {
    font-size: 90%;
  }
  
  .p-toast {
    font-size: 90%;
  }
  .p-toast .p-toast-icon-close-icon {
    font-size: 90%;
  }
  
  .p-message {
    font-size: 90%;
  }
  .p-message .p-message-close .p-message-close-icon {
    font-size: 90%;
  }
  
  .p-tooltip .p-tooltip-text {
    font-size: 90%;
  }
  
  .p-component .p-menu-separator {
    border-color: #eeeeee;
  }
  
  .p-submenu-icon {
    color: #605e5c !important;
  }
  
  .p-menuitem-active .p-submenu-icon {
    color: #323130 !important;
  }
  
  .p-progressbar-label {
    display: none !important;
  }
  
  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: #9A294B;
  }
  
  .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: #9A294B;
  }
  
  .p-inputtext:disabled {
    background-color: #f3f2f1;
    border-color: #f3f2f1;
    color: #a19f9d;
    opacity: 1;
    user-select: none;
  }
  .p-inputtext:enabled:focus {
    box-shadow: inset 0 0 0 1px #9A294B;
  }
  
  .p-checkbox .p-checkbox-box.p-disabled,
  .p-radiobutton .p-radiobutton-box.p-disabled,
  .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container.p-disabled,
  .p-chips .p-chips-multiple-container.p-disabled {
    background-color: #f3f2f1;
    border-color: #f3f2f1;
    color: #a19f9d;
    opacity: 1;
    user-select: none;
  }
  .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus,
  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus,
  .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus,
  .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    box-shadow: inset 0 0 0 1px #9A294B;
  }
  
  .p-dropdown.p-disabled,
  .p-multiselect.p-disabled {
    background-color: #f3f2f1;
    border-color: #f3f2f1;
    color: #a19f9d;
    opacity: 1;
    user-select: none;
  }
  .p-dropdown.p-disabled .p-dropdown-label,
  .p-dropdown.p-disabled .p-dropdown-trigger-icon,
  .p-multiselect.p-disabled .p-dropdown-label,
  .p-multiselect.p-disabled .p-dropdown-trigger-icon {
    color: #a19f9d;
  }
  .p-dropdown:not(.p-disabled).p-focus,
  .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: inset 0 0 0 1px #9A294B;
  }
  
  .p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none;
    outline: 1px solid #605e5c;
    outline-offset: 2px;
  }
  